<template>
  <v-card outlined tile>
    <v-card-text>
      <v-card-subtitle>Theme Color</v-card-subtitle>
      <v-row>
        <v-col class="text-center" v-for="(c, i) in themeColors" :key="i">
          <v-btn x-small fab depressed :color="c" @click="$emit('input', c)" style="border: 1px solid #00000050;">
            <v-icon v-if="color === c">mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ThemeColorSelector",
  model: {
    prop: "color",
    event: "input",
  },
  props: {
    color: String,
  },
  data() {
    return {
      themeColors: [
        "#FFAF4BFF",
        "#F1D232FF",
        "#63D68EFF",
        "#9EEACBFF",
        "#57BECAFF",
        "#3DD5FFFF",
        "#575ECAFF",
        "#8457CAFF",
        "#CA5757FF",
        "#EEEEEEFF",
        "#222222FF",
      ],
    };
  },
};
</script>
