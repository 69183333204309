<template>
  <full-view :title="$t('app.route.storeProfiles')">
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-3" :style="getViewHeightStyle(36)">
        <v-form ref="form" v-model="valid" class="py-5 px-5">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.name"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.name')"
                    required
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.nameAr"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.nameAr')"
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.phone"
                    :counter="64"
                    :rules="rules.phone"
                    :label="$t('general.phone')"
                    required
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.remarks"
                    :counter="255"
                    :label="$t('general.remarks')"
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="item.description"
                    :counter="255"
                    :rules="rules.limited255"
                    :label="$t('general.description')"
                    dense
                    rows="3"
                  />
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="item.address"
                    :counter="255"
                    :rules="rules.limited255"
                    :label="$t('store.address')"
                    dense
                    rows="3"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <map-location-picker v-model="point" :rule="rules.coords" />
                </v-col>
                <v-col>
                  <v-select
                    v-model="item.status"
                    :items="getStatusOptions"
                    :item-text="getLocale.lang === 'ar' ? 'nameAr' : 'name'"
                    item-value="id"
                    :rules="rules.required"
                    :label="$t('general.status')"
                    required
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.minimumOrderPrice"
                    :label="$t('store.minimumOrderPrice')"
                    type="number"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.deliveryFees"
                    :label="$t('store.deliveryFees')"
                    type="number"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.freeDeliveryPrice"
                    :label="$t('store.freeDeliveryPrice')"
                    type="number"
                    required
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <theme-color-selector v-model="item.themeColor" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="4">
              <map-area-control v-model="area" :point="point" />
              <image-uploader
                :key-attr="item.uuid"
                :title="$t('general.logo')"
                dir="stores/logos"
                edit-mode
                avatar
              />
              <image-uploader
                :key-attr="item.uuid"
                :title="$t('store.banner')"
                dir="stores/banners"
                edit-mode
              />
            </v-col>
          </v-row>

          <br />

          <form-bar
            :valid="valid"
            @save="save"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import FormBar from "@/common/components/FormBar";
import ImageUploader from "@/common/components/ImageUploader";
import MapLocationPicker from "@/common/components/MapLocationPicker";
import MapAreaControl from "@/common/components/MapAreaControl";
import ThemeColorSelector from "@/common/components/ThemeColorSelector";

export default {
  name: "StoreProfile",
  components: {
    FullView,
    ImageUploader,
    FormBar,
    MapLocationPicker,
    MapAreaControl,
    ThemeColorSelector,
  },
  data() {
    return {
      valid: false,
      area: [],
      point: { latitude: 0, longitude: 0 },
      item: { id: 0, name: null, nameAr: null, status: null },
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Name is required",
          (v) => (!!v && v.length >= 2) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        coords: [(v) => !!v || "Coordinates are required"],
        phone: [
          (v) => !!v || "Phone number is required",
          (v) => (!!v && v.length >= 8) || "Not less than 8 numbers",
          (v) => {
            const re = /^[+\-/0-9 ]*$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Only numbers, spaces and (+ - or /) sepcial charactors"
            );
          },
        ],
        limited255: [
          (v) =>
            !v || v.length <= 255 || "This field is limited to 255 characters",
        ],
        required: [(v) => !!v || "This field is required"],
      },
    };
  },
  mounted() {
    // Object.assign(this.item, this.data);
    if(isNaN(this.getStoreIdParam)) {
      return;
    }

    if (!this.item || !this.item.id) {
      this.item = this.getStoreById(this.getStoreIdParam);
      if (!this.item) {
        this.loadUserAssignedStores().then(() => {
          this.item = this.getStoreById(this.getStoreIdParam);
          if (
            this.item.aosGeom &&
            this.item.aosGeom.geometry &&
            this.item.aosGeom.geometry.coordinates
          ) {
            this.area = this.item.aosGeom.geometry.coordinates;
          }
          this.point = {
            latitude: this.item.latitude,
            longitude: this.item.longitude,
          };
          Object.assign(this.originalState, this.item);
        });
      } else {
        if (
          this.item.aosGeom &&
          this.item.aosGeom.geometry &&
          this.item.aosGeom.geometry.coordinates
        ) {
          this.area = this.item.aosGeom.geometry.coordinates;
        }
        this.point = {
          latitude: this.item.latitude,
          longitude: this.item.longitude,
        };
        Object.assign(this.originalState, this.item);
      }
    }

    this.loadTypes(true);
  },
  computed: {
    ...mapGetters("stores", ["getStoreById"]),
    ...mapGetters("types", ["getTypesByGroup", "getTypeByGroupAndId"]),

    getStatusOptions() {
      const types = this.getTypesByGroup("STORE_STATUS");
      if (this.notEmptyArray(types)) {
        return types.map((m) => ({
          ...m,
          name: this.$t(`store.status.${this.camelize(m.name)}`),
        }));
      }
      return [];
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("stores", ["saveStore", "loadUserAssignedStores"]),

    save() {
      let item = { ...this.item };
      item.latitude = this.point.latitude;
      item.longitude = this.point.longitude;
      if(this.notEmptyArray(this.area) && this.notEmptyArray(this.area[0])) {
        item.aosGeom = {
          type: "Feature",
          properties: null,
          geometry: {
            type: "Polygon",
            coordinates: [[...this.area[0], this.area[0][0]]],
          },
        };
      } else {
        item.aosGeom = null;
      }
      // item.status = this.getType(item.status).id;
      // item.aosParam = JSON.stringify({
      //   "geometry": {
      //     "coordinate": this.area,
      //     "type": "Polygon",
      //   },
      //   "properties": null,
      //   "type": "Feature",
      // });
      this.saveStore({ item, editMode: !!this.getStoreIdParam }).then(() => {
        this.$router.push({ name: this.homePage });
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
