<template>
  <collapsable-card title="Store Geofence" flat compact>
    <markers-map :markers="getMarkerPreview" :geofence="getAosPreview" show-current-button :custom-buttons="[{ icon: 'mdi-shape-polygon-plus', click: openAreaDialog }]" />
    <!-- <v-btn small depressed @click="openAreaDialog" style="position: absolute; bottom: 10px; left: 10px;">
      <v-icon>mdi-shape-polygon-plus</v-icon>
      Edit
    </v-btn> -->
  </collapsable-card>
</template>

<script>
import { mapActions } from "vuex";
import CollapsableCard from "./CollapsableCard";
import MarkersMap from "@/common/components/MarkersMap";
import MapAreaDialog from "@/views/general/MapAreaDialog";

export default {
  name: "MapAreaControl",
  // eslint-disable-next-line vue/no-unused-components
  components: { MapAreaDialog, MarkersMap, CollapsableCard },
  model: {
    prop: "area",
    event: "input",
  },
  props: {
    area: Array,
    point: Object,
  },
  computed: {
    getArea: {
      get() {
        return this.area;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getAosPreview() {
      return this.notEmptyArray(this.area) ? this.area[0] : null;
    },
    getMarkerPreview() {
      if (!this.point) {
        return null;
      }

      return [
        {
          icon: "mdi-store",
          color: "#195567",
          class: "secondary pin",
          status: "MAKZANI",
          latitude: this.point.latitude,
          longitude: this.point.longitude,
          click: () => {},
        },
      ];
    },
  },
  methods: {
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    openAreaDialog() {
      const dialog = {
        translate: true,
        title: "store.aos",
        // text: "Select your store location",
        component: MapAreaDialog,
        // width: "400px",
        noGutter: true,
        fullscreen: true,
        persistent: true,
        params: {
          area: this.getArea,
        },
        actions: [
          {
            text: "Okay",
            click: ({ area }) => {
              this.getArea = area;
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: "Cancel",
            click: () => {
              this.closeDialog();
            },
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
  },
};
</script>
