import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":"","tile":""}},[_c(VCardText,[_c(VCardSubtitle,[_vm._v("Theme Color")]),_c(VRow,_vm._l((_vm.themeColors),function(c,i){return _c(VCol,{key:i,staticClass:"text-center"},[_c(VBtn,{staticStyle:{"border":"1px solid #00000050"},attrs:{"x-small":"","fab":"","depressed":"","color":c},on:{"click":function($event){return _vm.$emit('input', c)}}},[(_vm.color === c)?_c(VIcon,[_vm._v("mdi-check")]):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }