<template>
  <v-form ref="form" v-model="valid" >
    <!-- <pin-location-map v-model="params.point" :style="getViewHeightStyle(36)" /> -->
    <pin-location-map v-model="params.point" color="#195567" icon="mdi-store" />
  </v-form>
</template>

<script>
import PinLocationMap from "@/common/components/PinLocationMap";

export default {
  name: "MapLocationDialog",
  components: { PinLocationMap },
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      valid: false,
    }
  }
};
</script>
